<template lang="pug">
#main_training
  h1.main-page-title Обучение
  span
    | Данный раздел предназначен для помощи новым пользователям в освоении системы. Ниже представлены примеры для базовых рабочих сценариев.
    | Кроме того, Вам доступен
    |
    accessed-link(:name="Tabs.ReleaseNotesFront" target="_blank") Список изменений,
    |
    | в котором можно просматривать историю развития и улучшения системы.
  .articles-wrapper(v-for="group of articles" :key="group.key")
    .articles-title {{ group.name }}
    .articles
      training-card(
        v-for="s of group.list"
        :key="s.name"
        :title="s.name"
        :time="s.time"
        :type="s.type"
        :sections="s.sections"
        @click="redirect(s.key)"
      )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { Tabs, TrainingArticles } from "@/router/tabs";

import TrainingCard from "@/components/pages/training/TrainingCard.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "TrainingPage",
  components: {
    AccessedLink,
    TrainingCard,
  },
  setup() {

    const router = useRouter();

    function redirect(to: string) {
      router.push({ name: to })
    }

    const analytics = [Tabs.Training.CompanyAnalytics, Tabs.Training.IndustryAnalytics];
    const orders = [Tabs.Training.TenderServices, Tabs.Training.NotificationHistory];
    const settings = [Tabs.Training.AccountManagement, Tabs.Training.ChangePassword, Tabs.Training.UserManagement];

    const base = Object.values(Tabs.Training).filter(e => !settings.includes(e) && !orders.includes(e) && !analytics.includes(e) && e !== Tabs.Training.Main)

    const articles = [
      { name: 'Базовый функционал', key: 'base-articles', list: TrainingArticles.filter(e => base.includes(e.key)) },
      { name: 'Аналитика',key: 'analytics-articles',  list: TrainingArticles.filter(e => analytics.includes(e.key)) },
      { name: 'Услуги и уведомления', key: 'orders-articles', list: TrainingArticles.filter(e => orders.includes(e.key)) },
      { name: 'Настройки системы', key: 'settings-articles', list: TrainingArticles.filter(e => settings.includes(e.key)) },
    ]

    return {
      Tabs,
      TrainingArticles,
      redirect,
      articles,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/main";
@import '@/assets/styles/pages/training';

#main_training {
  @include adaptive-view;

  display: flex;
  flex-flow: column;
  gap: 16px;

  span {
    font-size: 14px;
  }
}

@media (max-width: 1080px) {
  .articles-wrapper {
    .articles {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 820px) {
  .articles-wrapper {
    .articles {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 560px) {
  .articles-wrapper {
    .articles {
      display: flex;
      flex-flow: column;
      gap: 16px;
    }
  }

  #main_training {
    span {
      font-size: 13px;
    }
  }
}
</style>
